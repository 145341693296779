import PropTypes from 'prop-types'
import React, { useState, useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import logo from '../images/logo-engie-blue.svg'
import logoCompact from '../images/logo-engie-small-blue.svg'
import { Link } from './link'
import ThemeSwitch from './elements/theme-switch'
import useBodyClass from '../utility/useBodyClass'
import useClickOut from '../utility/useClickOut'
import SitesSelect from './sites-select'
import Navigation from './navigation'
import Search from './search'
import AppContext from '../context/app-context'
// import locales from '../utility/locales'
import PreviewBar from './preview-bar'
import SearchIcon from '../images/icons/material/search.svg'
import PersonIcon from '../images/icons/material/person.svg'
import ExpandMoreIcon from '../images/icons/material/expand_more.svg'


// const checkActive = (props) => {
//   const { className, activeClassName, style, activeStyle } = props;
//   return ({ href, location }) => {
//     // console.log('checkActive: ', href, location)
//     const notDefaultLocales = Object.values(locales).filter(loc => !loc.isDefault);
//     const matchingLocale = notDefaultLocales.find(loc => location.pathname.startsWith(`/${loc.langPath}/` || location.pathname === `/${loc.langPath}`));
//     // console.log('matchingLocale: ', matchingLocale)
//     if ((matchingLocale && href === `/${matchingLocale.langPath}/`) || (href === '/' && !matchingLocale)) {
//       return {
//         className: [className, activeClassName].filter(Boolean).join(" "),
//         style: { ...style, ...activeStyle }
//       };
//     }
//   }
// }

const Header = ({ siteTitle, langCode, location }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen(value => !value), []);
  const [selectOpen, setSelectOpen] = useState(false);
  const toggleSelect = useCallback(() => setSelectOpen(value => !value), []);
  const [searchOpen, setSearchOpen] = useState(false);
  const toggleSearch = useCallback(() => setSearchOpen(value => !value), []);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const toggleUserMenu = useCallback(() => setUserMenuOpen(value => !value), []);

  useBodyClass(menuOpen || selectOpen, 'block-scroll-mobile');
  useBodyClass(searchOpen, 'block-scroll-modal');

  const menuRef = useClickOut(menuOpen, setMenuOpen);
  const selectRef = useClickOut(selectOpen, setSelectOpen);
  const searchRef = useClickOut(searchOpen, setSearchOpen);
  const userMenuRef = useClickOut(userMenuOpen, setUserMenuOpen);

  const { darkMode, setDarkMode } = useContext(AppContext);

  return (
    <div className="header">
      <div className="nj-header nj-header--fixed">
        <div className="nj-header__group">
          {process.env.NODE_ENV === 'development' &&
            <PreviewBar />
          }
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header__top d-flex align-items-center">
                  <div className="row w-100 mx-0">
                    <div className="col-4 d-inline-flex align-items-center pl-0">
                      {/* <Link to="/" checkActive={checkActive} localized={false}
                        activeClassName="header__link--active" className="header__link mr-1">IT</Link>
                      <Link to="/en/" checkActive={checkActive} activeClassName="header__link--active" className="header__link">EN</Link> */}
                      <ThemeSwitch className="" checked={darkMode} setChecked={setDarkMode} />
                    </div>
                    <div className="col-4 header__logo text-center">
                      <Link className="header__link h-100" to="/">
                        <img src={logo} className="h-100" alt={siteTitle} />
                      </Link>
                    </div>
                    <div className="col-4 d-inline-flex justify-content-end align-items-center pr-0">
                      <a href="https://footprint.engie.it/" target="_blank" rel="noopener noreferrer"
                        className="header__link header__link--active d-none d-xl-inline-block mr-1">
                        Carbon footprint
                      </a>
                      <Link to="/newsroom/press/contatti/" className="header__link d-none d-xl-inline-block mr-1">
                        <FormattedMessage id="contatti" />
                      </Link>
                      <button className="header__search mx-1" onClick={toggleSearch}>
                        <SearchIcon className="material-svg text-blue-corporate" />
                      </button>
                      <button className="header__user" onClick={toggleUserMenu}>
                        <PersonIcon className="material-svg text-blue-corporate ml-1" />
                        {userMenuOpen && (
                          <div ref={userMenuRef} className="header__usermenu text-left p-2">
                            <span className="d-flex align-items-center font-weight-bold">
                              Area Clienti
                            <ExpandMoreIcon className="material-svg ml-1" />
                            </span>
                            <a href="https://casa.engie.it/spazio-clienti?content=ACMADashboard"
                              target="_blank" rel="noopener noreferrer" className="d-block mb-1 mt-1">
                              Casa
                          </a>
                            {/* <a href="https://imprese.engie.it/spazio-clienti?content=ACMADashboard" 
                            target="_blank" rel="noopener noreferrer" className="d-block">
                            Condomini
                          </a> */}
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 position-static">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-inline-flex align-items-center w-100">
                    <div className="header__logo-compact mr-2">
                      <Link className="header__link w-100" to="/">
                        <img src={logoCompact} className="w-100" alt={siteTitle} />
                      </Link>
                    </div>
                    <SitesSelect open={selectOpen} onClick={toggleSelect} langCode={langCode} ref={selectRef} />
                  </div>
                  <Navigation open={menuOpen} ref={menuRef} langCode={langCode} location={location} />
                  <div className="header__buttons d-inline-flex align-items-center ml-auto">
                    <button className="header__search d-none d-xl-inline-block mr-1 mr-xl-0" onClick={toggleSearch}>
                      <SearchIcon className="material-svg text-blue-corporate" />
                    </button>
                    <button className={`hamburger d-xl-none${menuOpen ? ' open' : ''}`} onClick={toggleMenu}>
                      <span></span><span></span><span></span><span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {searchOpen && (
          <Search toggleSearch={toggleSearch} ref={searchRef} langCode={langCode} />
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
