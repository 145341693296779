import { useState, useLayoutEffect } from "react"
import throttle from 'lodash.throttle'


const useScroll = (threshold, throttleWait = 200) => {
  const [scrolled, setScrolled] = useState(false);

  useLayoutEffect(() => {
    const listener = throttle(() => {
      setScrolled((document.documentElement.scrollTop || document.body.scrollTop) >= threshold);
    }, throttleWait);
    document.addEventListener('scroll', listener);
    listener();
    return () => document.removeEventListener('scroll', listener);
  }, [threshold, throttleWait]);

  return scrolled;
}

export default useScroll;
