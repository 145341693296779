import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "./link";
import FooterSites from "./elements/footer-sites";
import { onetrustShowSettings } from "../utility/onetrust";
import Facebook from "../images/icons/facebook.svg";
import Instagram from "../images/icons/instagram.svg";
import Twitter from "../images/icons/twitter.svg";
import Youtube from "../images/icons/youtube.svg";
import Linkedin from "../images/icons/linkedin.svg";
import TikTok from "../images/icons/tiktok.svg";

const Footer = ({ langCode }) => {
  const { menuFooter } = useStaticQuery(graphql`
    {
      menuFooter: allLiferayMenufooter {
        edges {
          node {
            langCode
            firstColLinks {
              value
              content {
                firstColLabel {
                  value
                }
              }
            }
            secondColLinks {
              value
              content {
                secondColLabel {
                  value
                }
              }
            }
          }
        }
      }
    }
  `);
  
  const filteredMenu = menuFooter.edges
    .filter((edge) => edge.node.langCode === langCode)
    .map((edge) => edge.node);
  const { firstColLinks, secondColLinks } =
    filteredMenu && filteredMenu.length ? filteredMenu[0] : {};
  return (
    <div className="footer">
      <div className="nj-footer">
        <div className="nj-footer__content">
          <div className="container pb-3">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-6">
                <span className="footer__label mr-2">Sei in:</span>
                <FooterSites />
              </div>
              <div className="col-12 pt-6 pt-md-0 pb-5 pb-md-0 col-md-4 col-lg-3">
                <div className="container">
                  <div className="row">
                    <div className="col-6 pl-0">
                      <ul className="list-unstyled">
                        {firstColLinks?.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={item.value}
                              className="nj-footer__link text-blue-corporate"
                            >
                              {item.content &&
                                item.content.firstColLabel &&
                                item.content.firstColLabel.value}
                            </Link>
                          </li>
                        ))}
                        <li>
                          <button
                            className="nj-footer__link text-blue-corporate"
                            onClick={onetrustShowSettings}
                          >
                            Impostazione cookie
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6 pr-0">
                      {secondColLinks ? (
                        <ul className="list-unstyled">
                          {secondColLinks.map((item, i) => (
                            <li key={i}>
                              <Link
                                to={item.value}
                                className="nj-footer__link text-blue-corporate"
                              >
                                {item.content &&
                                  item.content.secondColLabel &&
                                  item.content.secondColLabel.value}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 pr-sm-7 pr-md-2 pb-5 pb-md-0 d-flex justify-content-between">
                <a
                  href="https://www.facebook.com/engieitalia"
                  className="footer__icon"
                  aria-label="Facebook"
                >
                  <Facebook />
                </a>
                <a
                  href="https://www.instagram.com/engieitalia"
                  className="footer__icon"
                  aria-label="Instagram"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.tiktok.com/@engieitalia"
                  className="footer__icon"
                  aria-label="TikTok"
                >
                  <TikTok />
                </a>
                <a
                  href="https://twitter.com/ENGIEitalia"
                  className="footer__icon"
                  aria-label="Twitter"
                >
                  <Twitter />
                </a>
                <a
                  href="https://www.youtube.com/c/ENGIEitalia"
                  className="footer__icon"
                  aria-label="Youtube"
                >
                  <Youtube />
                </a>
                <a
                  href="https://www.linkedin.com/company/engie-italia"
                  className="footer__icon"
                  aria-label="Linkedin"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-9 col-sm-5 col-lg-4 col-xl-3">
                <span className="footer__info">
                  ENGIE ITALIA S.p.A. - P. IVA 06289781004
                </span>
              </div>
              <div className="col-3 pr-0 pr-sm-2 col-lg-2">
                <Link to="/mappa-sito/" className="footer__info">
                  Mappa sito
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
