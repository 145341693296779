import React, { useContext, useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "./link";
import IntlWrapper from "./intl-wrapper";
import Header from "./header";
import Footer from "./footer";
import AppContext from "../context/app-context";
import useScroll from "../utility/useScroll";

const Layout = forwardRef(
  (
    {
      langCode,
      tags,
      children,
      location,
      headerVisible = true,
      footerVisible = true,
    },
    ref
  ) => {
    const { site } = useStaticQuery(
      graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `
    );

    const dataTags = useMemo(
      () =>
        tags && tags.length
          ? tags.map((item) => item.taxonomyCategoryName)
          : [],
      [tags]
    );

    const scrolled = useScroll(200);

    const { darkMode } = useContext(AppContext);
    var mailBar = true;

    if (
      location?.pathname === "/lavora-con-noi/" ||
      location?.pathname === "/illustrazione-modello-integrato/"
    ) {
      mailBar = false;
    }

    return (
      <IntlWrapper locale={langCode}>
        <div
          className={`${
            location?.pathname !== "/illustrazione-modello-integrato/"
              ? "wrapper"
              : ""
          }${
            scrolled &&
            location?.pathname !== "/illustrazione-modello-integrato/"
              ? " wrapper--scrolled"
              : ""
          }${darkMode ? " wrapper--dark" : ""}`}
        >
          {headerVisible && (
            <Header
              siteTitle={site.siteMetadata.title}
              langCode={langCode}
              location={location}
            />
          )}
          {dataTags.map((tag) => (
            <span className="tag-argument d-none" key={tag}>
              {tag}
            </span>
          ))}
          {mailBar && (
            <Link
              to="/newsroom/press/contatti/"
              className="lateral-bar d-none d-lg-block"
              aria-label="Contacts"
            >
              <i className="nj-icon nj-icon-envelope nj-icon--circle size-60"></i>
            </Link>
          )}
          <div ref={ref}>
            {children}
            {footerVisible && <Footer langCode={langCode} />}
          </div>
        </div>
      </IntlWrapper>
    );
  }
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
