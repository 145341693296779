import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { injectIntl } from 'react-intl'
import locales from '../utility/locales'

// Fine tune this on project needs
const INTERNAL_REGEX = new RegExp(`^/(?!/)`);

const GlobalLink = ({ to, checkActive, children, localized = true, intl: { locale }, ...props }) => {
  if (!to) return <div {...props} >{children}</div>;
  if (INTERNAL_REGEX.test(to)) {
    const { className, activeClassName, style, activeStyle } = props;
    const localizedTo = (locales[locale].isDefault || !localized) ? to : (
      to.startsWith(`/${locales[locale].langPath}/`) ? to : `/${locales[locale].langPath}${to}`
    );

    // Gestione activeClassName indipendente da slash finale o meno
    const isActive = ({ isPartiallyCurrent, href, location }) => {
      const pathname = location.pathname;
      const strippedHref = href.endsWith('/') ? href.substring(0, href.length - 1) : href;
      const strippedPathname = pathname.endsWith('/') ? pathname.substring(0, pathname.length - 1) : pathname;
      if (strippedHref === strippedPathname) {
        return {
          className: [className, activeClassName].filter(Boolean).join(" "),
          style: { ...style, ...activeStyle }
        };
      }
      return null;
    }
    const normalized = localizedTo.endsWith('/') || localizedTo.includes('#') || localizedTo.includes('?') 
      ? localizedTo : `${localizedTo}/`;
    return (
      <GatsbyLink getProps={checkActive ? checkActive(props) : isActive} to={normalized} {...props}>
        <>
          {children}
        </>
      </GatsbyLink>
    );
  }
  // Remove activeClassName prop from external links
  const { activeClassName, className, ...other } = props;
  return (
    <a href={to} className={`external-link${className ? ` ${className}` : ``}`} target="_blank" rel="noopener noreferrer" {...other}>
      <>
        {children}
      </>
    </a>
  );
}

export const Link = injectIntl(GlobalLink);