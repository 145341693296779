import React from 'react'
import { IntlProvider } from 'react-intl'

import en1 from '../i18n/en/messages.json'
import it1 from '../i18n/messages.json'
const messages = { 'en-US': { ...en1 }, 'it-IT': { ...it1 } }


const IntlWrapper = ({ locale, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]} defaultLocale="it-IT">
    {children}
  </IntlProvider>
);

export default IntlWrapper;