import React, { useState, forwardRef, useCallback, useMemo } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, RefinementList, InfiniteHits, Highlight, Configure } from 'react-instantsearch-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import thumbnail from '../images/content/thumbnail-engie.png'
import locales from '../utility/locales'
import CloseIcon from '../images/icons/material/close.svg'


const ALGOLIA_INDEX_NAME = process.env.GATSBY_ALGOLIA_INDEX_NAME;
const algoliaClient = algoliasearch('S99W8AP645', 'a32c39cca40b4a1f63a4ccbc26ce7462');
const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => params.query.length > 2)) {
      return algoliaClient.search(requests);
    } else {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
  },
};

const Hit = ({ hit }) => {
  // console.log('hit: ', hit)
  return (
    <a href={hit.url} className="search__card" >
      <div className="search__card-image d-none d-lg-block">
        <img src={hit.image || thumbnail} alt="" />
      </div>
      <div className="search__card-body px-3 py-2">
        {hit.tag ? (
          <span className="search__tag mb-2 text-uppercase">
            {typeof hit.tag === 'string' ? hit.tag : hit.tag[0]}
          </span>
        ) : null}
        <h4 className="search__card-title">
          <Highlight hit={hit} attribute="title" tagName="mark" />
        </h4>
      </div>
    </a>
  )
};

const Search = forwardRef(({ toggleSearch, langCode }, ref) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = useCallback(() => setFiltersVisible(true), []);
  const closeFilters = useCallback(() => setFiltersVisible(false), []);

  const [showRefinement, setShowRefinement] = useState(false);
  const onSearchStateChange = useCallback(searchState => {
    setShowRefinement(searchState.query.length > 2);
    return searchState;
  }, []);

  const intl = useIntl();
  const filters = useMemo(() => {
    const locale = locales[langCode];
    return locale ? `lang:${locale.lang}`: '';
  }, [langCode]);

  return (
    <div className="search" ref={ref}>
      <div className="search__content pt-2">
        <button className="search__close" onClick={toggleSearch}>
          <CloseIcon className="material-svg" />
        </button>
        <div className="container">
          <InstantSearch searchClient={searchClient} onSearchStateChange={onSearchStateChange} 
            indexName={ALGOLIA_INDEX_NAME}>
            <Configure filters={filters} />
            <div className="row pt-4 pt-lg-0">
              <div className="col-12">
                {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                <SearchBox autoFocus={true} translations={{ 
                  placeholder: intl.formatMessage({ id: "search-placeholder"}) 
                }} />
              </div>
            </div>
            {showRefinement ? (
              <div className="row">
                <div className="col-lg-4 py-2 d-none d-lg-block">
                  <h4 className="font-weight-bold"><FormattedMessage id="search-filters" /></h4>
                  <h5 className="font-weight-bold"><FormattedMessage id="search-thematics" /></h5>
                  <RefinementList attribute="tag" />
                </div>
                <div className="col-lg-8 py-2">
                  <InfiniteHits hitComponent={Hit} translations={{ 
                    loadMore: intl.formatMessage({ id: "search-loadMore"})
                   }} />
                </div>
              </div>)
            : null}
            {showRefinement ? (
              <div className="row d-lg-none">
                <div className="col-12 text-center py-2">
                  <button className="nj-btn nj-btn--primary" onClick={showFilters}>
                    <FormattedMessage id="search-filters" />
                  </button>
                </div>
              </div>
            ): null}
            <div className={`search__mobile-filters p-3 d-lg-none${filtersVisible ? ' search__mobile-filters--open' : ''}`}>
              <div className="row py-3">
                <div className="col-12">
                  <h4 className="font-weight-bold"><FormattedMessage id="search-filters" /></h4>
                  <h5 className="font-weight-bold"><FormattedMessage id="search-thematics" /></h5>
                  <RefinementList attribute="tag" />
                  <button className="nj-btn mt-2" onClick={closeFilters}>
                    <FormattedMessage id="close" />
                  </button>
                </div>
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
    </div>
  );
});

export default Search;
