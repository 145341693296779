const slugify = require('slugify');
const locales = require('./locales');

const DUMMY_BASE_URL = `https://www.example.com`;
const pageUrl = (page, skipHash = false) => {
  const { pageTitle, langCode } = page;
  const langPath = !locales[langCode].isDefault ? `${locales[langCode].langPath}/` : '';
  if (pageTitle.content && pageTitle.content.friendlyUrl && pageTitle.content.friendlyUrl.value) {
    const friendlyUrlValue = pageTitle.content.friendlyUrl.value;
    const { pathname, hash } = new URL(friendlyUrlValue, DUMMY_BASE_URL);
    const urlPath = pathname.startsWith('/') ? pathname.substring(1) : pathname;
    return `${langPath || ''}${urlPath.endsWith('/') ? urlPath : `${urlPath}/`}${hash && !skipHash ? hash : ''}`;
  } else {
    return `${langPath || ''}${slugify(pageTitle.value, { lower: true })}/`
  }
}

module.exports = pageUrl;