const locales = {
  'it-IT': {
    isDefault: true,
    lang: 'it',
    langPath: ''
  },
  'en-US': {
    lang: 'en',
    langPath: 'en'
  }
};

module.exports = locales;